<template>
    <div style="background-color: #f7f7f7;margin:-10px -10px 0 -10px;">
        <div class="product-list bbs-primary-color">
            <div class="title">产品圈子</div>
            <div class="list">
                <div class="item" v-for="item in product" :key="item.name">
                    <div class="img-thumb">
                        <img :src="item.src" alt="">
                    </div>
                    <div class="item-title">
                        {{ item.title }}
                    </div>
                    <div class="item-title1">
                        {{ item.title1 }}
                    </div>
                    <div class="item-title2">
                        {{ item.title2 }}
                    </div>
                </div>
            </div>
        </div>

        <div class="sum-list bbs-primary-color user-card">
            <div class="title">
                数据总览
            </div>
            <div class="list">
                <div class="item" v-for="item in sum" :key="item.name">
                    <div class="value">
                        <count-to :startVal='0' :endVal="0" :duration="800"></count-to>
                    </div>
                    <div class="item-title">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
        <div class="sum-list bbs-primary-color">
            <div class="title">
                公告栏
            </div>
            <div class="notice">
                <p>Bigemap Pro支持栅格数据、矢量数据、航拍影像、卫星影像高程等高线制作编辑、发布。
                    支持三维模型：BIM OSGB OBJ等等。
                    可定制化、个性化设置用户需求。</p>
            </div>
        </div>
        <div class="sum-list bbs-primary-color">
            <div class="title">
                最近回答
            </div>
            <div class="list">

            </div>
        </div>
        <div class="user-card">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
import CountTo from 'vue-count-to';

export default {
    components: {
        CountTo
    },
    data() {
        return {
            product: [
                {title: 'Bigemp Gis Office', icon: 'el-icon-setting',title1:'新GIS行业基础软件',title2:'桌面端(PC端)',src:'/static/bbs/images/product/pro1.png', sum: 0, name: 'gis'},
                {title: 'Bigemp SDK', icon: 'el-icon-warning-outline',title1:'离线地图服务器',title2:'WEB端(PC端)',src:'/static/bbs/images/product/sdk1.png', sum: 0, name: 'sdk'},
                {title: 'Bigemp APP', icon: 'el-icon-warning-outline',title1:'新GIS行业基础软件',title2:'移动端(Android和IOS)',src:'/static/bbs/images/product/app1.png', sum: 0, name: 'app'},
            ],
            sum: [
                {title: '积分', icon: 'el-icon-warning-outline', sum: 0, name: 'coin'},
                // {title: '赞', icon: 'el-icon-setting', sum: 0, name: 'ok'},
                {title: '提问', icon: 'el-icon-warning-outline', sum: 0, name: 'answer'},
                {title: '回答', icon: 'el-icon-warning-outline', sum: 0, name: 'help'},
            ],
        }
    },
    methods: {}
}
</script>
<style scoped lang="less">
.product-list{
    padding: 15px;
    background-color: #fff;
    margin-top: 1.5rem;

    .title {
        font-weight: 600;
    }

    .list {
        display: flex;
        align-items: center;
        padding: 10px 0;
        justify-content: space-around;

        .item {
            .item-title {
                font-size: 22px;
            }
        }
    }

    .item{
        text-align: center;
        border:1px solid #ddd;
        border-radius: 10px;
        padding: 20px;
        width: 215px;
        box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1), 0 7px 18px 0 rgba(21, 111, 170, 0.21);
        .img-thumb{
            //max-width: 220px;
            height: 90px;
            img{
                max-width: 100%;
            }
        }

        .item-title1{
            color: #555;
            padding: 15px 0;
        }
        .item-title2{
            color: #999;
        }
    }
}

.sum-list {
    padding: 15px;
    background-color: #fff;
    margin-top: 10px;

    .title {
        font-weight: 600;
    }

    .list {
        display: flex;
        align-items: center;
        padding: 10px 0;
        justify-content: space-around;

        .item {
            .item-title {
                font-size: 22px;
            }
        }
    }
}

.sum-list{
    .list {
        .item {
            text-align: center;
            .value{
                font-size: 22px;
            }
            .item-title {
                padding-top:10px;
                font-size: 14px;
            }
        }
    }
    .notice{
        color: #666;
        padding-top: 10px;
        text-indent: 25px;
        line-height: 25px;
    }
}
</style>
